import Pristine from 'pristinejs';

const config = {
    // class of the parent element where the error/success class is added
    classTo: 'form-group',
    errorClass: 'has-error',
    successClass: 'has-success',
    // class of the parent element where error text element is appended
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass:
        'text-help text-red relative font-bold max-h-0 mt-3 text-[13px] px-3 py-1 rounded w-fit transition-all ease-in-out',
};

export default (action = '', form) => ({
    status: 'clear',
    action,
    pristine: null,
    init() {
        // create the pristine instance
        this.pristine = new Pristine(form, config, false);

        form.addEventListener('submit', (e) => {
            e.preventDefault();
            // check if the form is valid
            if (this.status == 'submitting') {
                return;
            }
            this.status = 'submitting';
            window.setTimeout(() => {
                const valid = this.pristine.validate(); // returns true or false
                if (valid) {
                    // assign form action
                    e.target.action = this.action;
                    e.target.submit();
                } else {
                    this.status = 'dirty';
                    this.scrollToError();
                }
            }, 300);
        });

        this.loadTextArea();
    },
    scrollToError() {
        const errors = this.pristine.getErrors();
        const firstErrorId = errors[0].input.id;
        const firstErrorInput = document.getElementById(firstErrorId);
        window.setTimeout(
            () => {
                firstErrorInput.parentElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                });
            },

            300
        );
    },
    loadTextArea() {
        const tx = this.$el.getElementsByTagName('textarea');
        if (tx) {
            for (let i = 0; i < tx.length; i++) {
                let scrollHeight;
                scrollHeight =
                    tx[i].scrollHeight > 80 ? tx[i].scrollHeight : 80;
                tx[i].setAttribute(
                    'style',
                    'height:' + scrollHeight + 'px;overflow-y:hidden;'
                );
                tx[i].addEventListener('input', OnInput, false);
            }

            function OnInput() {
                this.style.height = 'auto';

                let scrollHeight;
                scrollHeight = this.scrollHeight > 80 ? this.scrollHeight : 80;

                this.style.height = scrollHeight + 'px';
            }
        }
    },
});
