if (import.meta.env.MODE !== 'development') {
    import('vite/modulepreload-polyfill');
}

import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import intersect from '@alpinejs/intersect';
import collapse from '@alpinejs/collapse';

import alpineForm from './modules/_alpine-form.js';
import reservationStepper from './modules/_alpine-reservation-stepper.js';
import reservationStepperXtreme from './modules/_alpine-reservation-stepper-xtreme.js';
import reservationDates from './modules/_alpine-reservation-dates.js';

// import chartsReservationsPie from './modules/_alpine-charts-reservations-pie.js';
// import chartsReservationsByRound from './modules/_alpine-charts-reservations-by-round.js';

Alpine.plugin(collapse);
Alpine.plugin(intersect);
Alpine.plugin(focus);

Alpine.data('form', alpineForm);
Alpine.data('reservationStepper', reservationStepper);
Alpine.data('reservationStepperXtreme', reservationStepperXtreme);
Alpine.data('reservationDates', reservationDates);

// Alpine.data('chartsReservationsPie', chartsReservationsPie);
// Alpine.data('chartsReservationsByRound', chartsReservationsByRound);

Alpine.start();
