export default (action) => ({
    action,
    init() {
        this.$watch('selectionTimestamp', (value) => {
            //console.log('selectionTimestamp');
            // let's add a delay to prevent issues with requests already made
            setTimeout(() => {
                this.months = [];
                this.pushedMonths = [];
                this.currentMonth = null;
            }, 200);
        });
        this.$watch('currentStep', (value) => {
            //console.log(value);
        });
        this.$watch('months', (value) => {
            //console.log('months', value);
            //console.log('currentMonth', this.currentMonth);
            // if the currentMonth is the last item, preload another month
        });
        this.$watch('currentMonth', (value, oldValue) => {
            if (this.currentStep < 1) {
                return;
            }
            if (value && oldValue && value.handle == oldValue.handle) {
                return;
            }
            //console.log('editing currentMonth', value);
            if (
                this.currentMonth &&
                this.currentMonth.handle ==
                    Object.values(this.months)[
                        Object.values(this.months).length - 1
                    ].handle
            ) {
                this.preloadNextMonth();
            }
        });
        window.addEventListener('scroll', (e) => {
            this.checkMonthInViewport();
        });
        //window.onscroll = this.checkMonthInViewport;
    },
    checkMonthInViewport() {
        let innerHeight = window.innerHeight;

        let scrollTop =
            window.pageYOffset !== undefined
                ? window.pageYOffset
                : (
                      document.documentElement ||
                      document.body.parentNode ||
                      document.body
                  ).scrollTop;

        // this is the threshold to see what month is relevant
        let halfViewport =
            scrollTop + innerHeight / 2 - (innerHeight / 100) * 30;

        // find last element that is above half viewport
        if (this.months && Array.isArray(this.months)) {
            let currentMonthIndex = 0;

            for (let i = 0; i < this.months.length; i++) {
                const monthEl = document.getElementById(this.months[i].handle);
                if (monthEl) {
                    if (monthEl.offsetTop < halfViewport) {
                        // console.log(this.months[i].handle, ' is above');
                        currentMonthIndex = i;
                    } else {
                        currentMonthIndex = i - 1;
                        break;
                    }
                }
            }

            const relevantMonth = this.months[currentMonthIndex];
            if (relevantMonth) {
                this.currentMonth = {
                    handle: relevantMonth.handle,
                    label: relevantMonth.label,
                };
            }
        }
    },
    async getInitMonthData() {
        const d = new Date(this.phpTimestamp * 1000);
        let month = d.getMonth();
        await this.getMonthData(month + 1);
        // after 5 minutes, let's fetch new month data
        setTimeout(() => {
            this.markLastChangeTimestamp();
            this.getInitMonthData();
        }, 300000);
    },
    months: [],
    pushedMonths: [],
    currentMonth: null,

    selectTimeForActivity(activityId, day, time) {
        this.error = null;

        const activityIndex = this.reservationData.activities.findIndex(
            (a) => a.activityId == activityId
        );

        if (activityIndex > -1) {
            if (
                this.reservationData.activities[activityIndex].timestamp ==
                    day &&
                this.reservationData.activities[activityIndex].round == time
            ) {
                this.reservationData.activities[activityIndex].timestamp = null;
                this.reservationData.activities[activityIndex].round = null;
            } else {
                this.reservationData.activities[activityIndex].timestamp = day;
                this.reservationData.activities[activityIndex].round = time;
            }
        }
    },

    async getMonthData(month) {
        //console.log('getMonthData', month);
        this.datesLoading = true;
        let formData = new FormData();
        formData.append(this.csrfTokenName, this.csrfTokenValue);
        for (let activity of this.reservationData.activities) {
            formData.append('activities[]', activity.activityId);
        }
        formData.append('month', month);

        await fetch(this.action, {
            method: 'POST',
            body: formData,
            headers: {
                Accept: 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // if month days are empty, skip to following month
                const daysArray = Object.values(data.days);
                // console.log(daysArray);
                let daysWithRounds = daysArray.filter((day) => {
                    return !Array.isArray(day.rounds);
                });

                if (daysWithRounds.length > 0) {
                    if (!this.pushedMonths.includes(data.handle)) {
                        // convert days to sorted array
                        let daysArray = Object.values(data.days);
                        daysArray.sort((a, b) => a.timestamp - b.timestamp);
                        data.days = daysArray;

                        // ! Conversion of round to array
                        for (let day of data.days) {
                            if (day.rounds) {
                                let roundsArray = Object.values(day.rounds);
                                roundsArray.sort((a, b) => a.time - b.time);
                                day.rounds = roundsArray;
                            }
                        }
                        // ! Conversion of round to array

                        this.months.push(data);
                        this.pushedMonths.push(data.handle);
                    }
                } else {
                    this.getMonthData(month + 1);
                }

                if (!this.currentMonth && this.months.length > 0) {
                    this.currentMonth = {
                        handle: this.months[0].handle,
                        label: this.months[0].label,
                    };
                    // set first day available label
                    //console.log(this.months[0].days);

                    const firstDayIndex = this.months[0].days.findIndex(
                        (day) => {
                            return !Array.isArray(day.rounds);
                        }
                    );

                    if (firstDayIndex > -1) {
                        this.months[0].days[firstDayIndex].isFirstDay = true;
                    }
                }

                this.datesLoading = false;
            })

            .catch((error) => {
                console.log(error);
                this.datesLoading = false;
            });
    },

    getCurrentMonthIndex(handle) {
        return this.months.findIndex((m) => m.handle == handle);
    },

    async preloadNextMonth() {
        // find the right month handle
        // console.log('preloadNextMonth');
        const currentMonthIndex = this.getCurrentMonthIndex(
            this.currentMonth.handle
        );

        // check to see if i need to load another month
        if (this.months.length <= currentMonthIndex + 1) {
            await this.getMonthData(Number(this.currentMonth.handle) + 1);
        }
    },

    async loadAndScrollToNextMonth() {
        // find the right month handle
        const currentMonthIndex = this.getCurrentMonthIndex(
            this.currentMonth.handle
        );

        // check to see if i need to load another month
        if (this.months.length == currentMonthIndex + 1) {
            await this.getMonthData(Number(this.currentMonth.handle) + 1);
            // async preload next month
        }

        // the next month is index + 1
        const nextMonthObject = this.months[currentMonthIndex + 1];

        const nextMonth = document.getElementById(nextMonthObject.handle);

        var yPosition = nextMonth.offsetTop;
        scroll({
            top: yPosition - 85,
            behavior: 'smooth',
        });
        //nextMonth.scrollIntoView();
        this.currentMonth = {
            handle: nextMonthObject.handle,
            label: nextMonthObject.label,
        };
    },
    scrollToPreviousMonth() {
        // find the current month index

        const currentMonthIndex = this.getCurrentMonthIndex(
            this.currentMonth.handle
        );

        if (currentMonthIndex > 0) {
            // the prev month is index + 1
            const prevMonthObject = this.months[currentMonthIndex - 1];

            this.currentMonth = {
                handle: prevMonthObject.handle,
                label: prevMonthObject.label,
            };

            const prevMonth = document.getElementById(prevMonthObject.handle);

            var yPosition = prevMonth.offsetTop;
            scroll({
                top: yPosition - 20,
                behavior: 'smooth',
            });
        }
    },
});
